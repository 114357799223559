import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="py-4">
        <div class="copyright">
          <div class="container text-center ">
            <p></p>
            Copyright ©
            <a
              id="copyright"
              className="text-decoration-none text-white"
              href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
              target="_blank"
            >
              Jiliko Asia Casino Play Jili games
            </a>
            . All Rights Reserved.
          </div>
        </div>
        {/* <div class="side_nav">
                <a id="BackTop" 
                // style="display: inline; opacity: 1;"
                >
                    <img class="img_fit" src="./img/top.png"/>
                </a>
            </div> */}
      </footer>
    </div>
  );
};

export default Footer;
