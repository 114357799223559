import React from "react";
import Hero from "../componentes/home/Hero";
import Favorite from "../componentes/home/Favorite";
import Download from "../componentes/home/Download";
import Bonuses from "../componentes/home/Bonuses";
import Security from "../componentes/home/Security";

const Home = () => {
  return (
    <>
     
      <Hero />
      <main>
        <Favorite/>
        <Download/>
        <Bonuses/>
        <Security/>
      </main>
    </>
  );
};

export default Home;
