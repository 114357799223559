import React from "react";

const Security = () => {
  return (
    <div>
      <div class="bg-b py-5">
        <div class="container main center">
          <h3 class="title col-lg-12 text-center">
            Security is a top priority at jiliko casino.
          </h3>
          <div class="content">
            <p>
              At jiliko casino, we have made it our top priority to prioritize
              security measures and ensure that our players can play with a
              sense of peace and protection. We understand that our customers
              entrust us with their sensitive information, and we take that
              responsibility very seriously. We have put in place a variety of
              measures that actively work to safeguard our players' personal and
              financial information from any unauthorized access. Our advanced
              security technology and dedicated team of experts work around the
              clock to detect and prevent any suspicious activity on our site.
              We also regularly conduct security assessments and audits to
              guarantee that our safety protocols are up-to-date and up to the
              highest industry standards. Our players can rest assured that at
              jiliko casino, we spare no effort in providing them with the
              highest level of secure and worry-free gambling experiences.
            </p>
          </div>
          <div class="content"></div>
          <h3 class="title col-lg-12 text-center">
            Enjoy high-quality gaming experience at jiliko casino
          </h3>
          <div class="content">
            <p>
              At jiliko casino, players can indulge in a diverse range of gaming
              options that are tailored to cater to all kinds of tastes and
              preferences. You can bet on a multitude of games, ranging from
              exciting slot machines to live casino, sportsbook, and poker games
              with various limits. With outstanding graphics and sound effects,
              each game takes players on a thrilling journey that keeps them
              entertained for hours on end. Besides the exceptional quality of
              its games, jiliko also boasts of a user-friendly interface that
              makes it easy for gamers to navigate through the various sections
              on the website. What's more, the mobile app enhances the overall
              gaming experience by letting users play their favorite games on
              the go. So, if you're on the lookout for a reliable, high-quality
              online casino, jiliko is your ultimate destination.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
