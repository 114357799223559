import React from "react";
import Advantages from "./Advantages";

const Hero = () => {
  return (
    <div className="home-hero">
      <section className=" d-flex justify-content-center align-items-center">
        <div className="container py-5 py-lg-0">
          <div className="row  ">
            <div className="col-lg-6 col-md-12 col-sm-12 order-lg-1 order-sm-2">
              <div className="title">
                <h2 className="hero-title">
                  JILIKO Asia Casino is the top online casino in the Philippines
                </h2>
                <p class="p">
                  JILIKO ASIA casino is the most reputable and trusted online
                  casino platform that provides the ultimate gaming experience
                  for players in the Philippines. With its exceptional quality
                  games, top-notch customer service, and user-friendly
                  interface, JILIKO online casino has been the go-to destination
                  for online casino enthusiasts. The casino boasts a wide range
                  of games such as slot machines, poker, baccarat, and many
                  more.
                </p>
                <p class="p">
                  Moreover, players can enjoy seamless and secure online
                  transactions with a variety of payment options available. The
                  customer support team is always ready and available to assist
                  players with any queries or concerns around the clock. With
                  its state-of-the-art technology and impressive graphics,
                  JILIKO casino is a remarkable online casino that provides a
                  thrilling and entertaining experience like no other. Join now
                  to experience the ultimate gaming adventure in the
                  Philippines.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 order-lg-2 order-sm-1 h-auto d-flex justify-content-center align-items-center">
              <img
                src="/assets/img/jiliko-banner.png"
                alt="Hero Banner"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>
      <Advantages />
    </div>
  );
};

export default Hero;
