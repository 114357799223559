import React from "react";

const Favorite = () => {
  return (
    <div>
      <section class="bg-b py-5">
        <div class="container main ">
          <h3 class="title text-center">
            Play all your favorite games at jiliko casino Online Gaming
          </h3>
          <div class="content text-center">
            <p class="p">
              If you are an avid online gaming enthusiast, then jiliko is the
              perfect online platform for you. We are undoubtedly a popular
              online gaming platform that stands out from the rest of our
              competitors. Our platform provides you with an extensive range of
              exciting gaming options that will undoubtedly capture your
              interest and keep you engaged. Whether you prefer sports betting,
              casino games, or slot machines, we have got you covered.
            </p>
          </div>
          <div className="row gap-3 game-fav">
            <div className="col-lg-12 box">
              <a
                class="all-height text-decoration-none"
                href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
              >
                <div class="row bg ">
                  <div class="col-lg-6 col-md-12 col-sm-12 text-center   justify-content-start">
                    <img
                      class="img_fit"
                      src="/assets/img/home-slot.jpg"
                      alt="Jiliko casino"
                      className="w-100"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12   content p-3">
                    <p class="title ">Slot game</p>
                    <p class="p text-white">
                      As a top-notch online casino, jiliko provides a plethora
                      of slot games that come in a wide range of genres, themes,
                      and formats to cater for every player's preferences.
                      Whether you're a seasoned player or a newcomer to the
                      online gaming world, you'll find a game that suits your
                      taste among our extensive list of offerings.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-12 box">
              <a
                class="all-height text-decoration-none"
                href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
              >
                <div class="row bg ">
                  <div class="col-lg-6 col-md-12 col-sm-12 text-center   justify-content-start">
                    <img
                      class="img_fit"
                      src="/assets/img/home-fish.jpg"
                      alt="Jiliko casino"
                      className="w-100"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12   content p-3">
                    <p class="title ">Fisher Game</p>
                    <p class="p text-white">
                      jiliko casino is one of the best online betting site that
                      offers fish games. We have a wide variety of tournaments
                      and daily challenges to keep you entertained and winning
                      big! This is an exciting shooting game in which you can
                      have fun underwater adventures, in addition to many
                      different game themes, you will surely find something you
                      like.
                    </p>
                  </div>
                </div>
              </a>
            </div>{" "}
            <div className="col-lg-12 box">
              <a
                class="all-height text-decoration-none"
                href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
              >
                <div class="row bg ">
                  <div class="col-lg-6 col-md-12 col-sm-12 text-center   justify-content-start">
                    <img
                      src="/assets/img/home-slot.jpg"
                      alt="Jiliko casino"
                      className="w-100"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12   content p-3">
                    <p class="title ">Live Casino</p>
                    <p class="p text-white">
                      The live casino section of jiliko is absolutely
                      exceptional, providing players with an exhilarating
                      experience that will leave them on the edge of their
                      seats! All your favourite games such as blackjack,
                      roulette, baccarat, and poker are available to play in
                      real-time with the aid of expert live dealers who are just
                      waiting to give you the ultimate gaming experience.
                    </p>
                  </div>
                </div>
              </a>
            </div>{" "}
            <div className="col-lg-12 box">
              <a
                class="all-height text-decoration-none"
                href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
              >
                <div class="row bg ">
                  <div class="col-lg-6 col-md-12 col-sm-12 text-center  justify-content-start">
                    <img
                      src="/assets/img/home-sport.jpg"
                      alt="Jiliko casino"
                      className="w-100"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12   content p-3">
                    <p class="title ">Sport</p>
                    <p class="p text-white">
                      At jiliko, we are proud to offer our players a
                      comprehensive sports betting section that boasts a wide
                      range of sporting options to choose from. Whether you are
                      a fan of football, tennis, basketball or esports, we have
                      got all your favorite sports covered with exciting and
                      diverse betting options available.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-12 box">
              <a
                class="all-height text-decoration-none"
                href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
              >
                <div class="row bg ">
                  <div class="col-lg-6 col-md-12 col-sm-12 text-center   justify-content-start">
                    <img
                      src="/assets/img/home-sabong.jpg"
                      alt="Jiliko casino"
                      className="w-100"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12   content p-3">
                    <p class="title ">Sabong</p>
                    <p class="p text-white">
                      jiliko online casino presents an incredible feature that
                      is sure to excite sports lovers! You can now indulge in
                      the thrill of sabong (cockfighting) betting and enjoy the
                      most fantastic betting experience of your life! Whether
                      you are a seasoned pro or a newcomer to sabong betting,
                      the incredible betting options and chances to win big are
                      sure to keep you on the edge of your seat!
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Favorite;
