import React from "react";

const Download = () => {
  return (
    <div>
      <div class="bg-1">
        <div class="container py-5">
          <div class="row ">
            {" "}
            <div class="col-lg-6 col-md-12">
              <div class="content">
                <h3 class="title">
                  Download the jiliko mobile app today and start playing!
                </h3>
                <p class="p">
                  Are you looking for the ultimate online gaming experience?
                  Look no further than the jiliko mobile app! With our app, you
                  can take all of your favorite casino games with you wherever
                  you go. Whether you're on the train, waiting in line, or just
                  relaxing at home, you can access our latest and greatest games
                  and promotions right from your phone or tablet. Plus, our app
                  is easy to use – just download it, log in or register, and
                  you're ready to play! And since our app is designed for mobile
                  use, you can expect fast load times and smooth gameplay. So
                  why wait?
                </p>
              </div>
              <div class="flex content col-10 col-md-12">
                <a
                  class="btn-download m10 fw-bold text-decoration-none me-4"
                  href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download iOS APP
                </a>
                <a
                  class="btn-download m10 fw-bold text-decoration-none"
                  href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Android APP
                </a>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <img
                class="img_fit"
                src="/assets/img/home-img2.png"
                alt="Jiliko casino"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
