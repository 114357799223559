import React from "react";

const Advantages = () => {
  return (
    <div className="">
      <div className="container">
        <div className="title">
          <h3 className="title-advantages d-flex justify-content-center">
            Advantages of joining jiliko online casino
          </h3>
          <p class="p">
            Are you a fan of online casino games? If yes, then you might be
            wondering which online casino to join. With so many options
            available, it can be challenging to choose the best one. However,
            Jiliko casino is an excellent platform that is worth considering.
            Here are some of the advantages of joining jiliko casino to play.
          </p>
        </div>
        <div className="row my-4">
          <div className="col-lg-3 col-md-6 col-sm-12 pb-3">
            <div className="box text-center all-height p-4 ">
              <img
                src="/assets/img/home-slot.jpg"
                alt="advantages 1"
                className="w-100"
              />
              <p class="my-3 w2 w-main2">Wide Variety of Games</p>
              <p class="p">
                One of the most significant advantages of jiliko is that it
                offers a wide variety of games. Whether you are a slots fan or a
                table games enthusiast, you will find something that suits your
                preferences. With over 500 games to choose from, you will never
                get bored.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 pb-3">
            {" "}
            <div className="box text-center all-height p-4 ">
              <img
                src="/assets/img/home-fish.jpg"
                alt="advantages 1"
                className="w-100"
              />
              <p class="p w2 w-main2">Convenient and Accessible</p>
              <p class="p">
                Another advantage of jiliko online casino is that it is
                convenient and accessible. You can play your favorite games from
                anywhere and at any time. All you need is a reliable internet
                connection, and you are good to go.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 pb-3">
            {" "}
            <div className="box text-center all-height p-4 ">
              <img
                src="/assets/img/home-slot.jpg"
                alt="advantages 1"
                className="w-100"
              />
              <p class="p w2 w-main2">Convenient and Accessible</p>
              <p class="p">
                Another advantage of jiliko online casino is that it is
                convenient and accessible. You can play your favorite games from
                anywhere and at any time. All you need is a reliable internet
                connection, and you are good to go.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 pb-3">
            {" "}
            <div className="box text-center all-height p-4 ">
              <img
                src="/assets/img/home-sport.jpg"
                alt="advantages 1"
                className="w-100"
              />
              <p class="p w2 w-main2">Excellent Customer Support</p>
              <p class="p">
                Lastly, Jiliko casino provides excellent customer support to its
                players. If you encounter any issues or have any questions, you
                can reach out to their customer support team via email or live
                chat. They are available 24/7 and will assist you promptly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
