import React from "react";

const Bonuses = () => {
  return (
    <div>
      <div className="bg-main">
        <div className="container py-5">
          <h3 className="title text-center">
            jiliko casino offer members great bonuses
          </h3>
          <div className="content p-3 ">
            <p className="p text-center">
              As a valued player, we want to offer you an incredible opportunity
              to maximize your winnings and elevate your online gaming
              experience. Our casino is proud to introduce our latest promotion,
              which is sure to blow you away! When you register your account
              with us, not only will you gain access to our extensive library of
              exciting games, but you'll also receive a generous welcome package
              that includes an array of amazing bonuses.
            </p>
            <ul className=" row row-cols-1 row-cols-md-2 row-cols-lg-4 gap-3">
              <div className="col  ">
                <div className="box content all-height   text-center">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img_fit p-3 w-100"
                      src="/assets/img/pro01.jpg"
                      alt="Jiliko casino"
                    />
                  </a>
                  <p className="p pt-3 text-decoration-none">
                    300% welcome BONUS
                  </p>
                </div>
              </div>
              <div className="col  ">
                <div className="box content all-height   text-center">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img_fit p-3"
                      src="/assets/img/pro02.jpg"
                      alt="Jiliko casino"
                    />
                  </a>
                  <p className="p pt-3 text-decoration-none">
                    JILIKO Anniversary Daily 200% bonus
                  </p>
                </div>
              </div>
              <div className="col  ">
                <div className="box content all-height   text-center">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img_fit p-3"
                      src="/assets/img/pro03.jpg"
                      alt="Jiliko casino"
                    />
                  </a>
                  <p className="p pt-3 text-decoration-none">
                    Monthly iPhone award Extra car big prize
                  </p>
                </div>
              </div>
              <div className="col  ">
                <div className="box content all-height   text-center">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img_fit p-3"
                      src="/assets/img/pro04.jpg"
                      alt="Jiliko casino"
                    />
                  </a>
                  <p className="p pt-3 text-decoration-none">
                    300% WEEKEND BONUS
                  </p>
                </div>
              </div>
            </ul>
            <p className="p text-center">
              We don't believe in offering just one type of bonus - that's why
              we've designed a package that's tailored specifically to your
              needs. Whether you're a fan of free spins, cash bonuses, or other
              exciting perks, our welcome package has got you covered. So, what
              are you waiting for? Sign up today and get ready to experience the
              thrill of the casino with the added benefit of some incredible
              bonuses!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonuses;
