import React from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { navMenu } from "../../data/NavMenu";
const Navbar = () => {
  return (
    <header className="">
      <div className="container ">
        <nav class="navbar navbar-expand-lg text-white ">
          <div class="container">
            <a
              class="navbar-brand text-white "
              href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
            >
              <img
                src="https://jiliko.cc/wp-content/uploads/2023/05/JILIKO-LOGO.png"
                alt="logo"
                srcset=""
              />
            </a>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <AiOutlineMenu className="text-white" />
            </button>

            <div
              class="collapse navbar-collapse justify-content-lg-end"
              id="navbarText"
            >
              <span class="navbar-text text-white d-flex justify-content-end ">
                {navMenu.map((item) => (
                  <ul key={item} class="navbar-nav me-auto mb-2 mb-lg-0 ">
                    <li class="nav-item">
                      <a
                        className="nav-link text-white"
                        aria-current="page"
                        href="https://bj88ph.live/af/Zd946zCP/jilikoonline"
                      >
                        {item}
                      </a>
                    </li>
                  </ul>
                ))}
              </span>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
