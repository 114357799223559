import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./componentes/layout/Layout";
import Home from "./pages/home";
import './styles/header.css'
import './styles/home.css'
import './styles/footer.css' 

function App() {
 
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<Layout/>}>
            <Route path="/" element={<Home/>} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
